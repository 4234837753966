<template>
  <div>
    <b-container fluid>
      <button-back />
      <b-row class="mb-2">
        <b-col cols="12">
          <h3>Mi Empresa</h3>
        </b-col>
      </b-row>
      <section>
        <b-card no-body class="card-global overflow-hidden">
          <b-row class="mb-4">
            <b-col lg="12" md="12" sm="12">
              <b-button
                variant="secondary"
                class=""
                size="lg"
                @click.prevent="handlePayments"
              >
                <img
                  src="@/assets/images/boleto.png"
                  width="20px"
                  height="20px"
                  class="mr-1"
                  alt=""
                />
                Historial de Pagos
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <!-- <b-col lg="12" md="12" sm="12">
          <div class="text-center">
            <div class="image__preview mb-2">
              <div v-if="!photo">
                <img src="@/assets/images/default.png" />
              </div>
              <div v-else>
                <img :src="photo" />
                <button class="btn__delete" @click="RemovePhoto">
                  <b-icon-trash-fill />
                </button>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <b-button
                type="button"
                variant="primary"
                class="mr-2"
                size="sm"
                @click="$refs.fileInput.click()"
              >
                <b-icon-camera-fill />
                <input
                  id="file"
                  ref="fileInput"
                  type="file"
                  class="d-none"
                  name="file0"
                  accept=".jpg, .png, .jpeg"
                  @change="fileChange"
                />
              </b-button>
              <b-button
                type="button"
                variant="success"
                size="sm"
                :disabled="!image || isBusy"
                @click="savePhoto"
              >
                <b-spinner class="mr-1" v-if="isBusy" small />
                <b-icon-upload class="mr-1" v-else />
                Subir
              </b-button>
            </div>
          </div>
        </b-col> -->
            <b-col lg="4" md="6" sm="12" class="mb-3">
              <div role="group">
                <label for="name" class="label_input_black">Nombre</label>
                <b-form-input
                  id="name"
                  size="sm"
                  placeholder="Nombre"
                  :value="record?.name"
                  disabled
                />
              </div>
            </b-col>
            <b-col lg="4" md="6" sm="12" class="mb-3">
              <div role="group">
                <label for="nit" class="label_input_black">Nit</label>
                <b-form-input
                  id="nit"
                  type="number"
                  size="sm"
                  placeholder="Nit"
                  :value="record?.nit"
                  disabled
                />
              </div>
            </b-col>
            <b-col lg="4" md="6" sm="12" class="mb-3">
              <div role="group">
                <label class="label_input_black">Plan actual</label>
                <b-form-input
                  size="sm"
                  :value="
                    record?.historyPlan?.planEnterprisePrice?.planEnterprise
                      ?.name
                  "
                  disabled
                />
              </div>
            </b-col>
            <b-col lg="4" md="6" sm="12" class="mb-3">
              <div role="group">
                <label class="label_input_black"
                  >N° de usuarios permitidos</label
                >
                <b-form-input
                  size="sm"
                  :value=" record?.historyPlan?.planEnterprisePrice?.planEnterprise?.number_users"
                  disabled
                />
              </div>
            </b-col>
            <b-col lg="4" md="6" sm="12" class="mb-3">
              <div role="group">
                <label class="label_input_black"
                  >N° de transportes permitidos</label
                >
                <b-form-input
                  size="sm"
                  :value="record?.historyPlan?.planEnterprisePrice?.planEnterprise?.number_transportations"
                  disabled
                />
              </div>
            </b-col>
            <b-col lg="4" md="6" sm="12" class="mb-3">
              <div role="group">
                <label class="label_input_black">Precio del plan actual</label>
                <b-form-input
                  size="sm"
                  :value="formatPrice(record?.historyPlan?.total)"
                  disabled
                />
              </div>
            </b-col>
            <b-col lg="4" md="6" sm="12" class="mb-3">
              <div role="group">
                <label for="initial_date" class="label_input_black"
                  >Fecha inicial</label
                >
                <b-form-input
                  id="initial_date"
                  type="date"
                  size="sm"
                  placeholder="Fecha inicial"
                  :value="record?.historyPlan?.initial_date"
                  disabled
                />
              </div>
            </b-col>
            <b-col lg="4" md="6" sm="12" class="mb-3">
              <div role="group">
                <label for="final_date" class="label_input_black"
                  >Fecha final</label
                >
                <b-form-input
                  id="final_date"
                  type="date"
                  size="sm"
                  placeholder="Fecha final"
                  :value="record?.historyPlan?.final_date"
                  disabled
                />
              </div>
            </b-col>
            <b-col lg="4" md="6" sm="12" class="mb-3">
              <div role="group">
                <label for="cellphone" class="label_input_black">Celular</label>
                <b-form-input
                  id="cellphone"
                  size="sm"
                  placeholder="Celular"
                  :value="record?.cellphone"
                  disabled
                />
              </div>
            </b-col>
            <b-col lg="4" md="6" sm="12" class="mb-3">
              <div role="group">
                <label for="address" class="label_input_black">Dirección</label>
                <b-form-input
                  id="address"
                  size="sm"
                  placeholder="Dirección"
                  :value="record?.address"
                  disabled
                />
              </div>
            </b-col>
          </b-row>
          <b-row class="footer-modal-customer">
            <b-col lg="12" md="12" sm="12" class="d-flex justify-content-end">
              <b-button
                class="btn btn-warning"
                size="lg"
                @click.prevent="handleEditing"
              >
                <b-icon-pencil-square class="mr-1" />
                Actualizar
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </section>
    </b-container>

    <base-modal title="Actualizar Empresa" :id="id">
      <template v-slot:form>
        <form-enterprise :record="record" @save="updateEnterprise" />
      </template>
    </base-modal>

    <base-modal title="Historial de Pagos" id="history-plan" :size="'lg'">
      <template v-slot:form>
        <Payment :enterprise="record" />
      </template>
    </base-modal>
  </div>
</template>

<script>
import {
  BContainer,
  BCol,
  BRow,
  BFormInput,
  BButton,
  BCard,
  BIconPencilSquare,
} from "bootstrap-vue";
import FormEnterprise from "./FormEnterprise";
import Payment from "../enterprise/components/Payment";
import ActionCRUD from "@/mixins/ActionCRUD";
import BaseModal from "@/components/base/BaseModal";
import { mapGetters, mapActions } from "vuex";
import { API_ROUTES } from "../../config";
import ButtonBack from "@/components/buttons/ButtonBack";
import { formatNumber } from "@/utils";

export default {
  inject: ["myEnterpriseRepository"],
  components: {
    BContainer,
    BCol,
    BRow,
    BFormInput,
    BButton,
    BCard,
    // BIconCameraFill,
    // BIconTrashFill,
    // BIconUpload,
    BIconPencilSquare,
    // BSpinner,
    FormEnterprise,
    BaseModal,
    ButtonBack,
    Payment,
  },
  mixins: [ActionCRUD],
  data() {
    return {
      record: null,
      image: null,
      photo: "",
      isBusy: false,
      id: "update-enterprise-model",
    };
  },
  computed: {
    ...mapGetters("user", ["getEnterprise"]),
  },
  methods: {
    ...mapActions("user", ["loadEnterprise"]),
    fileChange(e) {
      const me = this;
      // Recoger el fichero
      const file = e.target.files[0];
      me.image = file;
      const { type } = file;
      if (type != "image/jpeg" && type != "image/jpg" && type != "image/png") {
        me.$bvToast.toast("¡Formato no permitido!", {
          title: "Los formatos permitidos son .jpg .jpeg .png",
          toaster: "b-toaster-bottom-right",
          variant: "danger",
          solid: true,
          appendToast: true,
        });
        me.RemovePhoto();
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          me.photo = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },
    RemovePhoto() {
      this.image = null;
      this.photo = null;
    },
    async savePhoto() {
      const me = this;
      try {
        me.isBusy = true;
        const formData = new FormData();
        formData.append("image", me.image);
        formData.append("nit", me.getEnterprise.nit);
        formData.append("enterpriseUuid", me.getEnterprise.uuid);

        await me.myEnterpriseRepository.saveImage(formData);
        me.image = null;
        me.loadEnterprise();
        me.$bvToast.toast("Logo guardado con exito", {
          title: "Logo guardado",
          variant: "success",
          solid: true,
          autoHideDelay: 3000,
        });
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
        me.image = null;
        me.loadLogo();
        me.$bvToast.toast("Error al guardar el logo.", {
          title: "Error",
          variant: "danger",
          solid: true,
          autoHideDelay: 3000,
        });
      } finally {
        me.isBusy = false;
      }
    },
    handleEditing() {
      const me = this;
      me.$bvModal.show(me.id);
    },
    async loadEnterprise() {
      const me = this;
      try {
        const { data } = await me.myEnterpriseRepository.find();
        me.record = data;
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      }
    },
    updateEnterprise() {
      const me = this;
      me.messageSuccess();
      me.$bvModal.hide(me.id);
      me.loadEnterprise();
    },
    loadLogo() {
      const me = this;
      me.photo =
        me.getEnterprise.photo !== null
          ? API_ROUTES.image.get + me.getEnterprise.photo
          : null;
    },
    formatPrice(value) {
      return formatNumber(value + "");
    },
    handlePayments() {
      const me = this;
      me.$bvModal.show("history-plan");
    },
  },
  mounted() {
    const me = this;
    me.loadEnterprise();
  },
};
</script>

<style lang="css" scoped>
.imagen {
  border: 1px solid #808080;
  width: 150px;
  height: 1500px;
  border-radius: 0.3rem;
  cursor: pointer;
}

.image__preview img {
  width: 150px;
  height: 150px;
}

.btn__delete {
  background-color: rgb(241, 58, 58);
  color: var(--white-color);
  font-size: 16px;
  padding: 3px 8px 3px 8px;
  border-radius: 0.3rem;
  border: none;
  letter-spacing: 0.5px;
  position: absolute;
}
</style>
