<template>
  <b-form @submit.prevent="updateData" ref="form" autocomplete="off">
    <b-row class="mb-3">
      <b-col lg="12" md="12" sm="12">
        <div class="label_input text-white">* Campos requeridos.</div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="cellphone" class="label_input">Celular</label>
          <b-form-input
            id="cellphone"
            size="sm"
            placeholder="Celular"
            v-model="formData.cellphone"
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="address" class="label_input">Dirección</label>
          <b-form-input
            id="address"
            size="sm"
            placeholder="Dirección"
            v-model="formData.address"
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="footer-modal-customer">
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button
          type="submit"
          size="lg"
          class="btn btn-success w-width"
          :disabled="isBusy || $v.$invalid"
        >
          <b-spinner class="mr-2" v-if="isBusy" small />
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import ActionCRUD from "@/mixins/ActionCRUD";

export default {
  inject: ["myEnterpriseRepository"],
  mixins: [ActionCRUD],
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BSpinner,
  },
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isBusy: false,
      formData: {
        address: "",
        name: "",
        nit: "",
        initial_date: "",
        final_date: "",
        planEnterprisePriceUuid: "",
        cellphone: "",
      },
    };
  },
  validations: {
    formData: {
      address: {
        required,
      },
      cellphone: {
        required,
      }
    },
  },
  async mounted() {
    const me = this;
    me.formData = { 
      address: me.record?.address,
      name: me.record?.name,
      nit: me.record?.nit,
      initial_date: me.record?.initial_date,
      final_date: me.record?.final_date,
      planEnterprisePriceUuid: me.record?.planEnterprisePriceUuid,
      uuid: me.record?.uuid,
      cellphone: me.record?.cellphone,
    };
  },
  methods: {
    ...mapActions("user", ["loadEnterprise"]),
    async updateData() {
      const me = this;
      try {
        me.isBusy = true;
        await me.myEnterpriseRepository.update(
          me.formData.uuid,
          me.formData
        );
        me.$emit("save");
      } catch (error) {
        const { message, status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
        me.errorSave(message)
      } finally {
        me.isBusy = false;
      }
    },
  }
};
</script>
